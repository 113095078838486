import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just visited a page that doesn&#39;t exist. Please try another page.</p>
  </Layout>
)

export default NotFoundPage
